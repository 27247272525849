import { keyframes } from '@emotion/react';

const move = keyframes`
  0% { bottom: 3%; }
  100% { bottom: -3%; }
`;

export const useStyles = {
  root: {
    zIndex: 2,
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    top: {
      xs: '89%',
      sm: '92.5%',
    },
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: `${move} 1s alternate ease-in-out infinite`,
    transition: theme => theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    '&.active': {
      opacity: 1,
    },

    '& svg': {
      fontSize: theme => theme.typography.pxToRem(60),
    },
  },
};