export const useStyles = {
  button: {
    fontSize: theme => theme.typography.pxToRem(16),
  },

  buttonAbout: {
    pl: 0,
    mt: 2,
  },

  services: {
    mt: {
      xs: 0,
      // md: -21,
      sm: -15.625,
    },
  },

  servicesWrap: {
    pt: {
      xs: '0 !important',
      sm: '10vh !important',
    },
  },

  buttonCentered: {
    textAlign: 'center',
  },
};