export const useStyles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    flexGrow: 1,
  },

  button: {
    fontSize: theme => theme.typography.pxToRem(16),
  },
};