import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

import { Reveal } from '../../hooks/reveal';
import { useStyles } from './scrollDown.styles';

export function ScrollDown({ color }) {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const reveal = () => (window.scrollY < 25) ? setIsActive(true) : setIsActive(false);

    window.addEventListener('scroll', reveal);
    return () => window.removeEventListener('scroll', reveal);
  }, [isActive]);

  return (
    <Box
      sx={{ ...useStyles.root }}
      className={isActive ? 'active' : ''}
    >
      <Reveal delay="0.75">
        <ExpandMoreRounded color={color} />
      </Reveal>
    </Box>
  );
};