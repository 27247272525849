import React from 'react';
import { Button } from 'gatsby-theme-material-ui';

import {
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';

import { Image } from '../../hooks/image';
import { useStyles } from './cardImage.styles';
import { Subtitle, TextContent } from '../utils/utils';

export function CardImage({ image, alt, title, content, to }) {
  return (
    <Card sx={{ ...useStyles.root }}>
      <Image
        alt={alt}
        filename={image}
        className="card-image"
      />
      <CardContent sx={{ ...useStyles.content }}>
        <Subtitle>{title}</Subtitle>
        <TextContent>{content}</TextContent>
      </CardContent>
      <CardActions>
        <Button
          to={to}
          size="large"
          sx={{ ...useStyles.button }}
          endIcon={<ChevronRightRounded />}
        >
          En savoir plus
        </Button>
      </CardActions>
    </Card>
  );
};