import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'gatsby-theme-material-ui';

import { Box, Grid, useMediaQuery } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';

import { Reveal } from '../hooks/reveal';
import { useStyles } from './homeView.styles';
import { Hero } from '../components/hero/Hero';
import { CardImage } from '../components/card/CardImage';
import { setIsScrollToServices } from '../store/appSlice';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { Title, TextContent } from '../components/utils/utils';
import { ScrollDown } from '../components/scrollDown/ScrollDown';
import { useResetFormServices } from '../hooks/useResetFormServices';

export default function HomeView() {
  const dispatch = useDispatch();
  const { isScrollToServices, navBannerHeightDesktop } = useSelector(state => state.app);
  const { author, siteNavigation } = useSiteMetadata();
  const { resetFormServices } = useResetFormServices();

  // handle scroll to service
  const services = useRef(null);
  const [offset, setOffset] = useState(null);
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  useEffect(() => {
    matches ? setOffset(navBannerHeightDesktop) : setOffset(0);
  }, [matches]);

  const scrollToServices = () => {
    if (services) {
      window.scrollTo({
        behavior: 'smooth',
        top: services.current.getBoundingClientRect().top - offset,
      });
    }
  };

  useEffect(() => {
    if (isScrollToServices) {
      setTimeout(() => {
        scrollToServices();

        setTimeout(() => {
          dispatch(setIsScrollToServices(false));
        }, 250);
      }, 500);
    }
  }, [isScrollToServices, offset]);

  return (
    <>
      <Hero images={[{
        filename: 'jokairHome.jpg',
        alt: 'A view of the Jokair software login page on a laptop',
      }, {
        filename: 'careHome.jpg',
        alt: 'A view of the Buddy device',
      }, {
        filename: 'cantorHome.jpg',
        alt: 'A view of the Cantor software on a laptop',
      }]} />
      <ScrollDown />
      <Box component="section">
        <Reveal>
          <Title component="h1">{author}, le sur mesure qui vous simplifie la vie !</Title>
          <TextContent>
            Nous sommes spécialisés dans la gestion des flux de données. De la réception à la transmission en passant par le traitement et le stockage, nos solutions gèrent vos données.
          </TextContent>
          <Button
            to={siteNavigation.links[2].url}
            size="large"
            sx={{ ...useStyles.button, ...useStyles.buttonAbout }}
            endIcon={<ChevronRightRounded />}
          >
            Découvrir {author}
          </Button>
        </Reveal>
      </Box>
      <Box component="section" ref={services} className="grey-dark">
        <Reveal>
          <Title>Simple, facile, efficace et sur mesure, optez pour {author} !</Title>
          <TextContent>
            Nous vous fournissons des outils sur mesure qu’il s’agisse d’objets connectés, de logiciels de management de la relation clients ou encore des systèmes d’alarmes. Nous vous assurons la gestion et le traitement des données échangées, incluant le flux d’informations.
            <br />
            Tous nos logiciels et applications sont développés en parfaite conformité aux dispositions légales en vigueur dans le pays d’utilisation.
          </TextContent>
        </Reveal>
      </Box>
      <Box component="section" sx={{ ...useStyles.servicesWrap }}>
        <Reveal>
          <Grid
            container
            spacing={2}
            sx={{ ...useStyles.services }}
          >
            {siteNavigation.services.map((val, i) => (
              <Grid key={i} item xs={12} sm={6}>
                <Reveal className="grid fill-height">
                  <CardImage
                    to={val.url}
                    // title={val.title}
                    content={val.description}
                    image={`${val.ref}_card.png`}
                    alt={`Illustration of the ${val.title} service`}
                  />
                </Reveal>
              </Grid>
            ))}
          </Grid>
        </Reveal>
      </Box>
      <Box component="section" className="button">
        <Reveal sx={{ ...useStyles.buttonCentered }}>
          <Button
            to={siteNavigation.links[siteNavigation.links.length - 1].url}
            size="large"
            variant="contained"
            onClick={resetFormServices}
            sx={{ ...useStyles.button }}
          >
            Contactez-nous
          </Button>
        </Reveal>
      </Box>
    </>
  );
};