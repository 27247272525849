import React from 'react';

import Seo from '../components/seo/Seo';
import HomeView from '../views/HomeView';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function Index() {
  const { siteNavigation } = useSiteMetadata();

  return (
    <>
      <Seo
        currentTitle={siteNavigation.links[0].title}
        currentDescription={siteNavigation.links[0].description}
      />
      <HomeView />
    </>
  );
};

